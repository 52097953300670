import React, { useState, useEffect} from "react";

import banner from "../assets/images/banneren.jpg";
import ahbapLogo from "../assets/images/ahbap-logo.png";
import tatkoLogo from "../assets/images/tatko.png";
import tatkologo2 from "../assets/images/Tatko-Logo.png";
import photo1 from "../assets/images/Photo 1.jpg";
import photo2 from "../assets/images/Photo 2.jpg";
import photo3 from "../assets/images/Photo 3.jpg";
import containerGray from "../assets/images/container-gray.svg";
import containerGreen from "../assets/images/container-green.svg";
import  PieChart  from "../PieChart";
import tatkologoen from "../assets/images/tatkoen.png";
import ahbapLogoen from "../assets/images/ahbap-logoen.png";
import "../assets/fonts/Montserrat-Regular.ttf";
import { Link } from "react-router-dom";




const HomeEn = () => {
  const [formData, setFormData] = useState({
    Fullname: '',
    Company: '',
    Amount: '',
    Image: '',
    NameListApprove: false
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [nameListDatas, setNameListDatas] = useState([]);

  const items = Array.from({ length: 65 }, (_, i) => i);

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.name == 'Image' ? event.target.files[0] : (event.target.name == 'NameListApprove' ? event.target.checked : event.target.value)
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
	var frm = new FormData();
	frm.append("Fullname", formData.Fullname);
	frm.append("Amount", formData.Amount);
	frm.append("Company", formData.Company);
	frm.append("NameListApprove", formData.NameListApprove);
	frm.append("Image", formData.Image);

	var requestOptions = {
	  method: 'POST',
	  body: frm,
	  redirect: 'follow'
	};

	fetch("https://yardimyolu.tatkolastik.com:1000/donation", requestOptions)
	  .then(response => response.text())
	  .then(result => console.log(result))
	  .catch(error => console.log('error', error));
	  
	  if(!alert('Your information has been successfully registered. Thank you for your help.')){window.location.reload();}
  };

  useEffect(() => {
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow'
		};
		
      fetch("https://yardimyolu.tatkolastik.com:1000/donation/nameListApprove", requestOptions)
	  .then(response => response.json())
	  .then(data => {
		  setNameListDatas(data);
	  })
	  .catch(error => console.log('error', error));
  }, []);

  const [donationAmount, setDonationAmount] = useState(0);

  useEffect(() => {	  

var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

fetch("https://yardimyolu.tatkolastik.com:1000/donation", requestOptions)
  .then(response => response.json())
  .then(data => {
	  setDonationAmount(data);
		
		const currIndex = Number(data / 45000) - 1;
        setActiveIndex(currIndex);
  })
  .catch(error => console.log('error', error));
  }, []);

  return (
    <div className="home">
      <header className="header">
        <a
          className="header__site"
          href="https://yardimyolu.tatkolastik.com"
        >
          yardimyolu.tatkolastik.com
        </a>
        <a href="https://yardimyolu.tatkolastik.com/">
          <h1 className="header__title"><strong>Now it's time to help Turkiye</strong></h1>
        </a>
        <nav className="">
        <ul>
          <li>
            <Link to="/">TR</Link>
          </li>
          <li>
            <Link to="/en">EN</Link>
          </li>          
        </ul>
          
        </nav>
      </header>
      <section className="content">
        <img className="w-100" src={banner} alt="" />
      </section>
      <section className="cards mt-4">
        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">We Achieved Our First Goal!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">22 Container Shelters</b>
            <p>$52,650 / 49,000 € / 990.000 ₺</p>
          </div>
        </div>
        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">We Achieved Our Second Goal!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">28 Container Shelters</b>
            <p>$66,500 / 62,050 € / 1.260.000 ₺</p>
          </div>
        </div>

        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">We Achieved Our Third Goal!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">15 Container Shelters</b>
            <p>$36,243 / 34,250 € / 685.000 ₺</p>
          </div>
        </div>

        
        
      </section>

      


      <section className="donation mt-4">
      <a href="https://www.afad.gov.tr/deprem-bagislari" target="_blank" rel="noreferrer"> 
        <img className="donation__image"  href="https://www.afad.gov.tr/deprem-bagislari" src={tatkologoen} alt="" />  </a>
        <p></p>
        <div className="donation__inner">
          
            
          
        </div>
        <a href="https://ahbap.org/bagisci-ol" target="_blank" rel="noreferrer"> 
        <img className="donation__image" href="https://ahbap.org/bagisci-ol" src={ahbapLogoen} alt="" /> </a>
      </section> <b></b>
      <div className="donation__inner"><b></b>
          
      You may access AFAD and Ahbap Association’s bank account information by clicking the buttons called “Donate”. 
          
        </div>
      


<section className="donation mt-4">

          
      </section>

      <section className="multibanner mt-4">
        <img className="multibanner__image" src={photo1} alt="" />
        <img className="multibanner__image" src={photo2} alt="" />
        <img className="multibanner__image" src={photo3} alt="" />
      </section>

      <section className="transfer">
        <p>
         
        </p>
      </section>

      





      
      

      <div className="info">
      
      We wholeheartedly believe that we will overcome these difficult days by acting together.


      
            </div>


      <div className="donation-section container mt-4">
        <div className="target">
          <div className="target-item">
            <div className="target__circle"></div>
            <div className="target__content">
              <div className="target__title">Our Goal:</div>
              <div className="target__amount">2.935.000 ₺</div>
            </div>
          </div>

          <div className="target-item -active">
            <div className="target__circle"></div>
            <div className="target__content">
              <div className="target__title">Amount of Donations Received:</div>
              <div className="target__amount">{donationAmount.toLocaleString("de-DE")} ₺</div>
            </div>
          </div>
        </div>
        <div className="chart">
          <PieChart donationAmount={donationAmount} />
        </div>
        
        <div className="form-container">
          <div className="text-center">
            <small className="text-muted">Please specify</small>
            <b>""EARTHQUAKE HELP"" </b>
            <small className="text-muted">in the description section of your receipt. ​ ​</small> 
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group pb-3">
                <label>Name - Surname</label>
                <input name="Fullname" onChange={handleChange} defaultValue={formData.Fullname} type="text" className="form-control" required />
              </div>
              <div className="form-group pb-3">
                <label>Name of your Company</label>
                <input name="Company" onChange={handleChange} defaultValue={formData.Company} type="text" className="form-control"required />
              </div>
              <div className="form-group pb-3">
                <label>Amount of the Donation</label>
                <input name="Amount" onChange={handleChange} defaultValue={formData.Amount} type="number" className="form-control" required />
              </div>
              <div className="form-group pb-3">
                <label>Receipt</label>
                <input name="Image" onChange={handleChange} defaultValue={formData.Image} type="file" className="form-control" required />
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="NameListApprove" onChange={handleChange} defaultValue={formData.NameListApprove} />
                <label className="form-check-label">
                I confirm my name to be published in the donor list
                </label>
              </div>  
              <button type="submit" className="btn">Submit</button>
              
            </form>
          </div>
        </div>
        <div className="col-2 nameListScroll" > 
          <span style={{fontWeight: 'bold'}}>Donator List</span>
          
          {nameListDatas.map((item, index) => (

<span className="nameList">
{item} 

</span>
          ))}
           
          
		    </div>
      </div>

      <div className="containers">
        
        {items.map((item, index) => (

        <div className="containers__item">
          <span>{index +1}.Container</span>
          <img key={item} src={index <= activeIndex ? containerGreen : containerGray} />
        </div>
        ))}
      </div>

      <div className="footer">
        <img src={tatkologo2} alt="" />
      </div>
    </div>


  );
};

export default HomeEn;
