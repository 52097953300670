import React, { useState, useEffect} from "react";

import banner from "../assets/images/banner.jpg";
import ahbapLogo from "../assets/images/ahbap-logo.png";
import tatkoLogo from "../assets/images/tatko.png";
import tatkologo2 from "../assets/images/Tatko-Logo.png";
import bagis from "../assets/images/tablo3.png"
import photo1 from "../assets/images/Photo 1.jpg";
import photo2 from "../assets/images/Photo 2.jpg";
import photo3 from "../assets/images/Photo 3.jpg";
import containerGray from "../assets/images/container-gray.svg";
import containerGreen from "../assets/images/container-green.svg";
import  PieChart  from "../PieChart";

import "../assets/fonts/Montserrat-Regular.ttf";
import { Link } from "react-router-dom";



const Home = () => {
  const [formData, setFormData] = useState({
    Fullname: '',
    Company: '',
    Amount: '',
    Image: '',
    NameListApprove: false
  });
  const [activeIndex, setActiveIndex] = useState(0);

  

  const [nameListDatas, setNameListDatas] = useState([]);

  //3. hedef gelecegi zaman 50'yi 60 yap
  const items = Array.from({ length: 65 }, (_, i) => i);

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.name == 'Image' ? event.target.files[0] : (event.target.name == 'NameListApprove' ? event.target.checked : event.target.value)
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
	var frm = new FormData();
	frm.append("Fullname", formData.Fullname);
	frm.append("Amount", formData.Amount);
	frm.append("Company", formData.Company);
	frm.append("NameListApprove", formData.NameListApprove);
	frm.append("Image", formData.Image);

	var requestOptions = {
	  method: 'POST',
	  body: frm,
	  redirect: 'follow'
	};

	fetch("https://yardimyolu.tatkolastik.com:1000/donation", requestOptions)
	  .then(response => response.text())
	  .then(data => { if(!alert('Bilgileriniz başarıyla kaydedilmiştir. Yardımlarınız için teşekkür ederiz.')) {window.location.reload();} })
	  .catch(error => { alert('Bilgilerinizi kaydederken hata oluştu lütfen tekrar deneyiniz.') });
  };

  useEffect(() => {
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow'
		};
		
      fetch("https://yardimyolu.tatkolastik.com:1000/donation/nameListApprove", requestOptions)
	  .then(response => response.json())
	  .then(data => {
		  //data.map ((item)=>(nameListDatas.push(item)))
      setNameListDatas(data);
	  })
	  .catch(error => console.log('error', error));
  });

  const [donationAmount, setDonationAmount] = useState(0);

  const [pieChartLabels, setPieChartLabels] = useState(["Toplam Bağış Tutarı", "Hedefe Kalan Tutar"]);

  useEffect(() => {	  

var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

fetch("https://yardimyolu.tatkolastik.com:1000/donation", requestOptions)
  .then(response => response.json())
  .then(data => {
	  setDonationAmount(data);
		
		const currIndex = Number(data / 45000) - 1;
        setActiveIndex(currIndex);
  })
  .catch(error => console.log('error', error));
  }, []);
  
  

  return (
    <div className="home">
      <header className="header">
        <a
          className="header__site"
          href="https://yardimyolu.tatkolastik.com"
        >
          yardimyolu.tatkolastik.com
        </a>
        <a href="#">
          <h1 className="header__title"><strong>Şimdi Yardım Zamanı!</strong></h1>
        </a>
        <nav className="">
        <ul>
          <li>
            <Link to="/en">EN</Link>
          </li>  
          <li>
            <Link to="/">TR</Link>
          </li>  
                  
        </ul>
          
        </nav>
      </header>
      <section className="content">
        <img className="w-100" src={banner} alt="" />
      </section>
      <section className="cards mt-4">
        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">1.Hedefimize Ulaştık!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">22 Adet Konteyner</b>
            <p>990 Bin TL</p>
          </div>
        </div>
        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">2.Hedefimize Ulaştık!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">28 Adet Konteyner</b>
            <p>1 Milyon 260 Bin TL</p>
          </div>
        </div>

        <div className="cards__item">
          <div className="cards__header -active">
            <h3 className="cards__title">3.Hedefimize Ulaştık!</h3>
          </div>
          <div className="cards__footer">
            <b className="cards__count">15 Adet Konteyner</b>
            <p>685 Bin TL</p>
          </div>
        </div>
        
      </section>

      


      <section className="donation mt-4">
      <a href="https://www.afad.gov.tr/deprem-bagislari" target="_blank" rel="noreferrer"> 
        <img className="donation__image"  href="https://www.afad.gov.tr/deprem-bagislari" src={tatkoLogo} alt="" />  </a>
        <p></p>
        <div className="donation__inner">
          
            
          
        </div>
        <a href="https://ahbap.org/bagisci-ol" target="_blank" rel="noreferrer"> 
        <img className="donation__image" href="https://ahbap.org/bagisci-ol" src={ahbapLogo} alt="" /> </a>
      </section> <b></b>
      <div className="donation__inner"><b></b>
          
      Bağış Yap butonlarını tıklayarak AFAD ve Ahbap Derneği hesap bilgilerine ulaşabilirsiniz.
          
        </div>
      


	<section className="donation mt-4">

          
      </section>

      <section className="multibanner mt-4">
        <img className="multibanner__image" src={photo1} alt="" />
        <img className="multibanner__image" src={photo2} alt="" />
        <img className="multibanner__image" src={photo3} alt="" />
      </section>

      <section className="transfer">
        <p>
         
        </p>
      </section>
      <div className="info">
      
      Zor Günleri El Ele Vererek Hep Birlikte Atlatacağız!
      
            </div>

      

      <div className="donation-section container mt-4">
        <div className="target">
          <div className="target-item">
            <div className="target__circle"></div>
            <div className="target__content">
              <div className="target__title">Hedefimiz:</div>
              <div className="target__amount">2.935.000 TL</div>
            </div>
          </div>

          <div className="target-item -active">
            <div className="target__circle"></div>
            <div className="target__content">
              <div className="target__title">Ulaşılan Bağış Miktarı:</div>
              <div className="target__amount">{donationAmount.toLocaleString("de-DE")} TL</div>
            </div>
          </div>
        </div>
        <div className="chart">
          <PieChart donationAmount={donationAmount} />
        </div>
        
        <div className="form-container">
          <div className="text-center">
            <small className="text-muted"><span color="#da1d1d">AFAD veya Ahbap Derneğine bağışınızı tamamladıktan sonra</span> lütfen dekontun açıklama bölümüne </small>
            <b>"YARDIM YOLU-DEPREM" </b>
            <small className="text-muted">ifadesini ekleyiniz.</small> 
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group pb-3">
                <label>Adınız Soyadınız</label>
                <input name="Fullname" onChange={handleChange} defaultValue={formData.Fullname} type="text" className="form-control" required />
              </div>
              <div className="form-group pb-3">
                <label>Kurum Adı</label>
                <input name="Company" onChange={handleChange} defaultValue={formData.Company} type="text" className="form-control" required />
              </div>
              <div className="form-group pb-3">
                <label>Bağış Miktarı</label>
                <input name="Amount" onChange={handleChange} defaultValue={formData.Amount} type="number" className="form-control" required />
              </div>
              <div className="form-group pb-3">
                <label>Dekont</label>
                <input name="Image" onChange={handleChange} defaultValue={formData.Image} type="file" className="form-control" required />
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="NameListApprove" onChange={handleChange} defaultValue={formData.NameListApprove} />
                <label className="form-check-label">
                Bağışçı listesinde ismimin yayınlanmasına onay veriyorum
                </label>
              </div>  
              <button type="submit" className="btn">Gönder</button>
              <a href="/Aydinlatma_Beyani_Bagis_icin.docx">Aydınlatma metni için tıklayınız</a>
            </form>
          </div>
        </div>
        <div className="col-2 nameListScroll" > 
          <span style={{fontWeight: 'bold'}}>Bağışçı Listemiz</span>
          
          {nameListDatas.map((item, index) => (

<span className="nameList">
{item} 

</span>
          ))}
           
          
		    </div>
      </div>

      <div className="containers">
        
        {items.map((item, index) => (

        <div className="containers__item">
          <span>{index +1}.Konteyner</span>
          <img key={item} src={index <= activeIndex ? containerGreen : containerGray} />
        </div>
        ))}
        
      </div>
      <div className="footer">
        <img src={tatkologo2} alt="" />
      </div>
    </div>


  );
};

export default Home;
